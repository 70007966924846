import React from "react";
import styled from "styled-components";
import { Pill } from "../../components/Pill";

const PillWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Interests = () => {
  return (
    <article>
      <h3>INTERESTS</h3>
      <PillWrapper>
        <Pill filled={false}>Technology</Pill>
        <Pill filled={false}>Guitar</Pill>
        <Pill filled={false}>Hiking</Pill>
        <Pill filled={false}>Travel</Pill>
        <Pill filled={false}>Running</Pill>
      </PillWrapper>
    </article>
  );
};

export default Interests;
